<template>
  <div>
    <getecma-form v-if="model" :submit="create">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="model.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Ford Cargo 815" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Unidade de Medida de Veículos*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="unidade de medida"
            rules="required"
            placeholder="Selecione a unidade de medida"
            :options="capacities"
            @on-change="onCapacitiesChange">
          </getecma-select>
        </div>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Criar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchCapacities } from '@/modules/capacity/capacity.service';

export default {
  name: 'GetecmaModelCreateInformation',
  components: {
  },
  inject: ['modelEditVm'],
  data() {
    return {
      model: this.modelEditVm.model,
      performer: getters.getUser(),
      capacities: [],
    };
  },
  mounted() {
    this.fetchCapacities();
  },
  methods: {
    goHistoryBack,
    create() {
      this.$emit('create');
    },
    async fetchCapacities() {
      try {
        const params = { limit: 'all', page: 1, search: '', type: 1 };
        const response = await fetchCapacities(params);
        this.capacities = response.rows.map(capacity => ({
          key: capacity.id,
          value: `${capacity.name} - ${capacity.liters} litros`,
        }));
      } catch (error) {
        console.error('Erro ao buscar as unidades de medida:', error);
      }
    },
    onCapacitiesChange(capacity) {
      this.model.capacity_id = capacity.key;
    },
  },
};
</script>
