<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="model" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="model.id">
        <getecma-header size="lg">Editar Modelo</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-model-edit-information @save="save" />
      </div>
      <div v-if="!model_id">
        <getecma-header size="lg">Adicionar Modelo</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-model-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenModel, goToModelForbiddenPage } from '@/modules/model/model.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getModelById, updateModelInformation, createNewModel } from '@/modules/model/model.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { MODELS_URL } from '@/modules/model/model.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaModelEditInformation from '@/modules/model/components/ModelEditComponent.vue';
import GetecmaModelCreateInformation from '@/modules/model/components/ModelCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaModelEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaModelEditInformation,
    GetecmaModelCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const modelEditVm = {};
    Object.defineProperty(modelEditVm, 'model', {
      get: () => this.model,
    });
    return { modelEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Modelos', path: MODELS_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      model: null,
      createdModel: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    model_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.model_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToModelForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToModelForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.model_id) {
        getModelById(this.model_id)
          .then(data => {
            this.model = data;
          })
          .catch(() => toastError('Erro ao obter modelo por ID'));
      } else {
        this.model = {
          name: '',
        };
      }
    },
    save() {
      if (this.model_id) {
        this.fullscreenLoading = true;
        updateModelInformation(this.model)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Modelo salvo!');
            goToOpenModel(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o perfil do modelo');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewModel(this.model);
      }
    },
    onCreateNewModel(model) {
      createNewModel(model)
        .then(data => {
          goToOpenModel(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar o modelo');
        });
    },
  },
};
</script>
