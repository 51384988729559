<template>
  <getecma-form v-if="model" :submit="save">

    <fieldset class="form-group mt--xl">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="model.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Ford Cargo 815" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Unidade de Medida de Veículos*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            :value="capacitySelected.name"
            name="unidade de medida"
            rules="required"
            placeholder="Selecione a unidade de medida"
            :options="capacities"
            @on-change="onCapacitiesChange">
          </getecma-select>
        </div>
      </div>
    </fieldset>
    <div class="mt--xl mb--md d--flex justify-content-end">
      <getecma-button
        size="xl"
        :round="false"
        class="fs--sm"
        @click="goHistoryBack()">
        Cancelar
      </getecma-button>
      <getecma-button
        native-type="submit"
        class="ms--md fs--sm"
        bg-color="success"
        :round="false"
        size="xl">
        Salvar
      </getecma-button>
    </div>
  </getecma-form>
</template>

<script>

import { toastError } from '@/services/toastService';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchCapacities, getCapacityById } from '@/modules/capacity/capacity.service';

export default {
  name: 'Model',
  inject: ['modelEditVm'],
  data() {
    return {
      performer: getters.getUser(),
      model: this.modelEditVm.model,
      capacities: [],
      capacitySelected: null,
    };
  },
  mounted() {
    this.fetchCapacities();
    this.fetchCapacity();
  },
  methods: {
    goHistoryBack,
    save() {
      this.$emit('save');
    },
    async fetchCapacities() {
      try {
        const params = { limit: 'all', page: 1, search: '', type: 1 };
        const response = await fetchCapacities(params);
        this.capacities = response.rows.map(capacity => ({
          key: capacity.id,
          value: `${capacity.name} - ${capacity.liters} litros`,
        }));
      } catch (error) {
        console.error('Erro ao buscar as unidades de medida:', error);
      }
    },
    async fetchCapacity() {
      getCapacityById(this.model.capacity_id)
        .then(data => {
          this.capacitySelected = data;
        })
        .catch(() => toastError('Erro ao obter a unidade de medida por ID'));
    },
    onCapacitiesChange(capacity) {
      this.model.capacity_id = capacity.key;
    },
  },
};
</script>
